// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-eating-js": () => import("./../../../src/pages/eating.js" /* webpackChunkName: "component---src-pages-eating-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pantry-js": () => import("./../../../src/pages/pantry.js" /* webpackChunkName: "component---src-pages-pantry-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipe-post.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-topic-articles-js": () => import("./../../../src/templates/topic-articles.js" /* webpackChunkName: "component---src-templates-topic-articles-js" */)
}

